import { CManagementCharge } from 'common/_classes/contractsV2';
import moment from 'moment';
import { useState } from 'react';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import AddPlus from 'atoms/AddPlus';
import { TabMenuProps, TabReact } from 'atoms/TabReact';
import { updateActiveContractInput } from 'store/contractsV2/contractDetailSliceV2';
import { MODE_OPTIONS, ModeProps, ViewProps } from 'common/api/miscellaneous';
import TabLabel from '../../TabLabel';
import ManagementChargesForm from './ManagementChargesForm';

const ManagementCharges = ({ mode, isViewPage }: ModeProps & ViewProps) => {
  const dispatch = useAppDispatch();

  const { activeContract } = useAppSelector(({ contractDetailV2 }: RootState) => contractDetailV2);

  const managementCharges = activeContract?.managementCharges || [];

  const [tabIndex, setTabIndex] = useState(0);

  const disabled = mode === MODE_OPTIONS.READ || isViewPage;

  const key = 'managementCharges';

  const onAddCharges = () => {
    const value = [...managementCharges, new CManagementCharge(null)];
    dispatch(updateActiveContractInput({ key, value }));
    setTabIndex(value.length - 1);
  };

  const onDelete = (index: number) => {
    const value = managementCharges.filter((_item, idx) => index !== idx);
    dispatch(updateActiveContractInput({ key, value }));
  };

  const getTabsMenu = (): TabMenuProps[] => {
    return managementCharges.map(({ startDate, endDate }, index: number) => {
      const start = moment(startDate).isValid() ? moment(startDate).format('DD/MM/YYYY') : '';
      const end = moment(endDate).isValid() ? moment(endDate).format('DD/MM/YYYY') : '';

      const dateText = `${start}${start && end ? ' - ' : ''}${end}`;

      return {
        key: `management-charges-tab-${index}`,
        label: (
          <TabLabel
            title="Management Charge"
            disabled={mode === MODE_OPTIONS.READ}
            deletable={managementCharges.length !== 1}
            index={index}
            onDelete={onDelete}
            activeIndex={tabIndex}
            tabsLength={managementCharges.length}
            dateText={dateText}
            setTabIndex={setTabIndex}
          />
        ),
        customTab: (
          <ManagementChargesForm
            key={index}
            mode={mode}
            index={index}
            isViewPage={isViewPage}
          />
        ),
      };
    });
  };

  const onTabChange = (index: number) => setTabIndex(index);

  return (
    <div className="border-bottom-sm-grayish-magenta-light p-b-s">
      <div className="grid">
        {!disabled && (
          <AddPlus
            label="Add charge period"
            onClick={onAddCharges}
            className="m-l-auto"
            disabled={true}
          />
        )}
      </div>

      <TabReact
        dataTest=""
        activeTabIndex={tabIndex}
        tabMenu={getTabsMenu()}
        onTabChange={onTabChange}
      />
    </div>
  );
};

export default ManagementCharges;

import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'apollo';
import { ForecastedTenancies } from 'common/_classes/forecastsV2/ForecastedTenancies';
import moment from 'moment';
import { RootState } from 'store';
import { v4 } from 'uuid';
import { IActiveForecast } from 'store/dashboard/forecasts2Slice';
import Contract2 from 'common/model/Contract2';
import Contract2Connection from 'common/model/Contract2Connection';
import Floor from 'common/model/Floor';
import { LIST_CONTRACTS2_ALL } from 'common/api/contracts2';
import { getContractEndDate } from 'utils/utils-contract';

enum TENANCY_TYPE {
  CURRENT = 'current',
  HOLD_OVER = 'hold over',
  HOLD_OVER_LIKELY_TO_RENEW = 'hold over, likely to renew',
  TRANSACTION_WITH_OFFER_LETTER = 'transaction with offer_ etter',
  TRANSACTION_WITHOUT_OFFER_LETTER = 'transaction without offer letter',
  EMPTY_SPACE = 'empty space',
  TENANCY_WITH_OPTION_TO_RENEW = 'tenancy with option to renew',
  EXPIRED_TENANCY_WITHOUT_OPTION_TO_RENEW = 'expired tenancy without option to renew',
  TERMINATED_EARLY = 'terminated early',
}

export const generateAssumedTenanciesFromContracts = async (
  buildingId: string,
  forecast: IActiveForecast | null,
  floorsList: Floor[],
) => {
  let assumedTenancies: ForecastedTenancies[] = [];
  if (!forecast) {
    return assumedTenancies;
  }
  const getFloorName = (floorId: string) => {
    const floor = floorsList.find(floor => floor.id === floorId);
    // console.log("floor", floor,floorId);
    return floor ? floor.name : floorId;
  };
  const addAssumedTenanciesFromContracts1 = (contracts: Contract2[]) => {
    const newTenancies = contracts.flatMap(contract => {
      const event = contract.events[contract.events.length - 1];
      const termEndDate = getContractEndDate(event?.endDate, event?.terminationDate);
      const commonData = {
        tenancyId: event.contract2.id,
        tenantName: event.tenant.name,
        tenantId: event.tenant.id,
        startDate: moment(event.startDate).format('YYYY-MM-DD'),
        endDate: moment(termEndDate).format('YYYY-MM-DD'),
        transactionId: '',
        forecastId: forecast?.id ?? '',
        type: TENANCY_TYPE.CURRENT,
      };

      return event.premises.floors.flatMap(floor =>
        floor.spaceIds.map(unitId => ({
          ...commonData,
          id: v4(),
          unitId,
          unitName: unitId,
          floorId: getFloorName(floor.floorId),
          wholeFloor: floor.whole,
        })),
      );
    });

    assumedTenancies.push(...newTenancies.map(tenancy => new ForecastedTenancies(tenancy)));
  };
  const addAssumedTenanciesFromContracts21 = (contracts: Contract2[]) => {
    assumedTenancies.push(
      ...contracts.flatMap(contract => {
        const event = contract.events[contract.events.length - 1];
        const termEndDate = getContractEndDate(event?.endDate, event?.terminationDate);
        const newTermEndDate = moment(termEndDate).add(2, 'months');

        return event.premises.floors.flatMap(floor =>
          floor.spaceIds.map(
            unitId =>
              new ForecastedTenancies({
                tenancyId: event.contract2.id,
                tenantName: event.tenant.name,
                tenantId: event.tenant.id,
                startDate: moment(termEndDate).format('YYYY-MM-DD'),
                endDate: moment(newTermEndDate).format('YYYY-MM-DD'),
                transactionId: '',
                forecastId: forecast.id,
                type: TENANCY_TYPE.HOLD_OVER,
                id: v4(),
                unitId,
                unitName: unitId,
                floorId: getFloorName(floor.floorId),
                wholeFloor: floor.whole,
              }),
          ),
        );
      }),
    );
  };
  const addAssumedTenanciesFromContracts22 = (contracts: Contract2[]) => {
    assumedTenancies.push(
      ...contracts.flatMap(contract => {
        const event = contract.events[contract.events.length - 1];
        const termEndDate = getContractEndDate(event?.endDate, event?.terminationDate);
        const newTermStartDate = moment(termEndDate).add(forecast?.voidMonths ?? 3, 'months');
        const newTermEndDate = newTermStartDate.add(moment(termEndDate).diff(moment(event?.startDate)));

        return event.premises.floors.flatMap(floor =>
          floor.spaceIds.map(
            unitId =>
              new ForecastedTenancies({
                tenancyId: event.contract2.id,
                tenantName: event.tenant.name,
                tenantId: event.tenant.id,
                startDate: moment(termEndDate).format('YYYY-MM-DD'),
                endDate: newTermEndDate.format('YYYY-MM-DD'),
                transactionId: '',
                forecastId: forecast.id,
                type: TENANCY_TYPE.HOLD_OVER_LIKELY_TO_RENEW,
                id: v4(),
                unitId,
                unitName: unitId,
                floorId: getFloorName(floor.floorId),
                wholeFloor: floor.whole,
              }),
          ),
        );
      }),
    );
  };

  const addAssumedTenanciesFromContracts6 = (contracts: Contract2[]) => {
    assumedTenancies.push(
      ...contracts.flatMap(contract => {
        const event = contract.events[contract.events.length - 1];
        const termEndDate = getContractEndDate(event?.endDate, event?.terminationDate);
        const newTermEndDate = moment(termEndDate).add(moment(termEndDate).diff(moment(event?.startDate)));

        return event.premises.floors.flatMap(floor =>
          floor.spaceIds.map(
            unitId =>
              new ForecastedTenancies({
                tenancyId: event.contract2.id,
                tenantName: event.tenant.name,
                tenantId: event.tenant.id,
                startDate: moment(termEndDate).format('YYYY-MM-DD'),
                endDate: newTermEndDate.format('YYYY-MM-DD'),
                transactionId: '',
                forecastId: forecast.id,
                type: TENANCY_TYPE.TENANCY_WITH_OPTION_TO_RENEW,
                id: v4(),
                unitId,
                unitName: unitId,
                floorId: getFloorName(floor.floorId),
                wholeFloor: floor.whole,
              }),
          ),
        );
      }),
    );
  };

  const addAssumedTenanciesFromContracts7 = (contracts: Contract2[]) => {
    assumedTenancies.push(
      ...contracts.flatMap(contract => {
        const event = contract.events[contract.events.length - 1];
        const termEndDate = getContractEndDate(event?.endDate, event?.terminationDate);
        const newTermStartDate = moment(termEndDate).add(forecast?.voidMonths ?? 3, 'months');
        const newTermEndDate = newTermStartDate.clone().add(moment(termEndDate).diff(moment(event?.startDate)));

        const commonData = {
          tenancyId: event.contract2.id,
          tenantName: event.tenant.name,
          tenantId: event.tenant.id,
          startDate: moment(termEndDate).format('YYYY-MM-DD'),
          endDate: newTermEndDate.format('YYYY-MM-DD'),
          transactionId: '',
          forecastId: forecast.id,
          type: TENANCY_TYPE.EXPIRED_TENANCY_WITHOUT_OPTION_TO_RENEW,
        };

        return event.premises.floors.flatMap(floor =>
          floor.spaceIds.map(unitId => ({
            ...commonData,
            id: v4(),
            unitId,
            unitName: unitId,
            floorId: getFloorName(floor.floorId),
            wholeFloor: floor.whole,
          })),
        );
      }),
    );
  };

  const addAssumedTenanciesFromContracts8 = (contracts: Contract2[]) => {
    assumedTenancies.push(
      ...contracts.flatMap(contract => {
        const event = contract.events[contract.events.length - 1];
        const termEndDate = event?.terminationDate;
        const newTermStartDate = moment(termEndDate).add(forecast?.voidMonths ?? 3, 'months');
        const termDuration = moment(termEndDate).diff(moment(event?.startDate));
        const newTermEndDate = moment(newTermStartDate).add(termDuration);

        return event.premises.floors.flatMap(floor =>
          floor.spaceIds.map(
            unitId =>
              new ForecastedTenancies({
                tenancyId: event.contract2.id,
                tenantName: event.tenant.name,
                tenantId: event.tenant.id,
                startDate: moment(termEndDate).format('YYYY-MM-DD'),
                endDate: newTermEndDate.format('YYYY-MM-DD'),
                transactionId: '',
                forecastId: forecast.id,
                type: TENANCY_TYPE.TERMINATED_EARLY,
                id: v4(),
                unitId,
                unitName: unitId,
                floorId: getFloorName(floor.floorId),
                wholeFloor: floor.whole,
              }),
          ),
        );
      }),
    );
  };
  const today = new Date();
  try {
    const response = await client.query(LIST_CONTRACTS2_ALL());
    const { edges, pageInfo }: Contract2Connection = response.data.listContracts2;
    const contracts = edges.map(element => element.node);
    console.log('contracts', contracts);
    const matchingContracts = contracts.filter(contract => {
      const event = contract.events[contract.events.length - 1];
      const premises = event.premises;
      const firstPremise = premises;
      return firstPremise?.property?.id === buildingId;
    });
    // 1. Get all tenancies that are current, no holdover flag
    const currentTenancies = matchingContracts.filter(contract => {
      const event = contract.events[contract.events.length - 1];
      const termStartDate = new Date(event?.startDate);
      const termEndDate = getContractEndDate(event?.endDate, event?.terminationDate);
      return termStartDate < today && termEndDate > today && !event.holdoverFlag;
    });
    addAssumedTenanciesFromContracts1(currentTenancies);
    // 2. Get all active tenancies with holdover, add two months assumed
    const holdOverTenancies = matchingContracts.filter(contract => {
      const event = contract.events[contract.events.length - 1];
      const termStartDate = new Date(event?.startDate);
      const termEndDate = getContractEndDate(event?.endDate, event?.terminationDate);
      return termStartDate < today && termEndDate > today && event.holdoverFlag && !event.holdoverLikelyToRenew;
    });
    addAssumedTenanciesFromContracts21(holdOverTenancies);

    //    Get all active tenancies with holdover and likely to renew, add holdover to likely
    const holdOverTenanciesLikelyToRenew = matchingContracts.filter(contract => {
      const event = contract.events[contract.events.length - 1];
      const termStartDate = new Date(event?.startDate);
      const termEndDate = getContractEndDate(event?.endDate, event?.terminationDate);
      return termStartDate < today && termEndDate > today && event.holdoverFlag && event.holdoverLikelyToRenew;
    });
    addAssumedTenanciesFromContracts22(holdOverTenanciesLikelyToRenew);

    // 6. Get all tenancies with option to renew - create assumed tenancies (option to renew terms extended)
    const tenanciesWithOptionToRenew = matchingContracts.filter(contract => {
      const event = contract.events[contract.events.length - 1];
      const termStartDate = event?.startDate ? new Date(event.startDate) : null;
      const termEndDate = getContractEndDate(event?.endDate, event?.terminationDate);
      return (
        termStartDate &&
        termStartDate < today &&
        termEndDate &&
        termEndDate > today &&
        !event.holdoverFlag &&
        event.renewFlag
      );
    });
    addAssumedTenanciesFromContracts6(tenanciesWithOptionToRenew);

    // 7. Get all expired tenancies without option to renew - create assumed tenancies

    const tenacniesExpiredWithoutOptionToRenew = matchingContracts.filter(contract => {
      const event = contract.events[contract.events.length - 1];
      const termEndDate = getContractEndDate(event?.endDate, event?.terminationDate);
      return termEndDate && termEndDate < today && event.renewFlag === false;
    });

    addAssumedTenanciesFromContracts7(tenacniesExpiredWithoutOptionToRenew);

    // 8. Get all assumed tenancies that have ended and are before forecast end, create follow on assumed tenancies
    const tenanciesTerminatedEarly = matchingContracts.filter(contract => {
      const event = contract.events[contract.events.length - 1];
      if (!event?.terminationDate) return false;
      const terminationDate = new Date(event.terminationDate);
      const termEndDate = getContractEndDate(event.endDate, event.terminationDate);
      return terminationDate < termEndDate;
    });
    addAssumedTenanciesFromContracts8(tenanciesTerminatedEarly);

    // console.log('response', matchingContracts);
    // console.log('assumedTenanciesContracts', assumedTenancies);
    return assumedTenancies;
  } catch (error) {
    console.error('Error fetching contracts:', error);
    throw error;
  }
};

// export const generateAssumedTenanciesFromTransactions = async (
//   buildingId: string,
//   forecast: IActiveForecast,
//   transactions: TransactionListingClone[],
// ) => {
//   let assumedTenancies: ForecastedTenancies[] = [];
//   try {
//     // 3. Get transactions with signed offer letter - create assumed tenancies
//     // 4. Get transactions without offer letter - create assumed tenancies
//     // 5. Get all empty spaces not in list above, - create assumed tenancies
//     //console.log('transactions', transactions);
//     //console.log('assumedTenanciesTransactions', assumedTenancies);
//     return assumedTenancies;
//   } catch (error) {
//     console.error('Error fetching contracts:', error);
//     throw error;
//   }
// };

export const generateAssumedTenanciesThunk = createAsyncThunk(
  'forecasts2/generateAssumedTenancies',
  async (
    { buildingId, forecast, floorsList }: { buildingId: string; forecast: IActiveForecast | null; floorsList: Floor[] },
    { getState, rejectWithValue },
  ) => {
    if (!forecast) {
      return rejectWithValue('Forecast is null');
    }
    // const { transactionsListing } = getState() as RootState;
    // console.log('transactionsListing', transactionsListing);
    try {
      const contractAssumedTenancies = await generateAssumedTenanciesFromContracts(buildingId, forecast, floorsList);
      // const transactionAssumedTenancies = await generateAssumedTenanciesFromTransactions(
      //   buildingId,
      //   forecast,
      //   transactionsListing.transactionsList,
      // );
      //return [...contractAssumedTenancies, ...transactionAssumedTenancies];
      console.log('contractAssumedTenancies', contractAssumedTenancies);
      return contractAssumedTenancies;
    } catch (error) {
      return rejectWithValue('Failed to generate assumed tenancies');
    }
  },
);

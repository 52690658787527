import Decimal from 'decimal.js';
import { useEffect } from 'react';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import DateField from 'atoms/FormField/DateInput';
import InputField, { InputFieldType } from 'atoms/FormField/Input';
import SelectField from 'atoms/FormField/Select';
import { updateActiveContractEventInput } from 'store/contractsV2/contractDetailSliceV2';
import RentReviewType from 'common/model/RentReviewType';
import { evaluateRenewalRightsDates } from 'common/api/contracts2';
import { MODE_OPTIONS, ModeProps } from 'common/api/miscellaneous';
import { RENT_REVIEW_METHOD_OPTIONS, WHOLE_OR_PART_OPTIONS } from 'utils/UI';

interface OptionToRenewFormProps extends ModeProps {
  index: number;
}

const OptionToRenewForm = ({ mode, index }: OptionToRenewFormProps) => {
  const dispatch = useAppDispatch();
  const { activeContractEvent } = useAppSelector(({ contractDetailV2 }: RootState) => contractDetailV2);
  const renewalRights = activeContractEvent?.renewalRights || [];

  const { cap, collar, renewalTermLength, increment, noticePeriodMonths, rent, type, wholeOrPart, startDate, endDate } =
    renewalRights[index];

  const onChange = (key: string, value: string) => {
    dispatch(
      updateActiveContractEventInput({
        key: ['renewalRights', `${index}`, key],
        value: value || null,
      }),
    );
  };

  const onChangeDecimal = (key: string, value: string) => {
    dispatch(
      updateActiveContractEventInput({
        key: ['renewalRights', `${index}`, key],
        value: value ? new Decimal(value) : null,
      }),
    );
  };

  const onChangeNumber = (key: string, value: string) => {
    dispatch(
      updateActiveContractEventInput({
        key: ['renewalRights', `${index}`, key],
        value: value ? parseInt(value) : null,
      }),
    );
  };

  const disabled = mode === MODE_OPTIONS.READ;
  const required = mode === MODE_OPTIONS.EDIT;

  useEffect(() => {
    if (mode === MODE_OPTIONS.EDIT && activeContractEvent?.endDate) {
      dispatch(evaluateRenewalRightsDates({ renewalRights, leaseEndDate: activeContractEvent?.endDate }));
    }
  }, [renewalTermLength, activeContractEvent?.endDate]);

  return (
    <div>
      <div className="grid m-t-s">
        <div className="col-2">
          <DateField
            label="Renewal term start date"
            fieldKey="startDate"
            dataTest=""
            value={startDate}
            disabled={true}
            required={false}
            onChange={() => {}}
          />
        </div>

        <div className="col-2">
          <DateField
            label="Renewal term end date"
            fieldKey="endDate"
            dataTest=""
            value={endDate}
            disabled={true}
            required={false}
            onChange={() => {}}
          />
        </div>
      </div>

      <div className="grid m-t-s">
        <div className="col-2">
          <InputField
            label="Renewal term length"
            dataTest=""
            type={InputFieldType.NUMBER}
            unit="(months)"
            fieldKey="renewalTermLength"
            value={renewalTermLength?.toString() || null}
            disabled={disabled}
            required={required}
            onChange={onChangeNumber}
            delimiter="COMMA"
          />
        </div>

        <div className="col-2">
          <SelectField
            label="Exercisable in whole or part"
            fieldKey="wholeOrPart"
            dataTest=""
            value={wholeOrPart}
            disabled={disabled}
            required={required}
            options={WHOLE_OR_PART_OPTIONS}
            onChange={onChange}
          />
        </div>

        <div className="col-2">
          <InputField
            label="Notice period"
            dataTest=""
            type={InputFieldType.NUMBER}
            unit="(months)"
            fieldKey="noticePeriodMonths"
            value={noticePeriodMonths?.toString() || null}
            disabled={disabled}
            required={required}
            onChange={onChangeNumber}
            delimiter="COMMA"
          />
        </div>
      </div>

      <div className="grid m-t-s">
        <div className="col-2">
          <SelectField
            label="Rent determination method"
            fieldKey="type"
            dataTest=""
            value={type}
            disabled={disabled}
            required={required}
            options={RENT_REVIEW_METHOD_OPTIONS}
            onChange={onChange}
          />
        </div>

        {(type === RentReviewType.OpenMarketPrOrOmr || type === RentReviewType.OpenMarketUpDown) && (
          <div className="col-2">
            <InputField
              label="Cap"
              dataTest=""
              type={InputFieldType.NUMBER}
              unit="(%)"
              fieldKey="cap"
              value={cap?.toString() || null}
              disabled={disabled}
              onChange={onChangeDecimal}
              isDecimal={true}
              delimiter="COMMA"
            />
          </div>
        )}

        {type === RentReviewType.OpenMarketUpDown && (
          <div className="col-2">
            <InputField
              label="Collar"
              dataTest=""
              type={InputFieldType.NUMBER}
              unit="(%)"
              fieldKey="collar"
              value={collar?.toString() || null}
              disabled={disabled}
              onChange={onChangeDecimal}
              isDecimal={true}
              delimiter="COMMA"
            />
          </div>
        )}

        {type === RentReviewType.New && (
          <div className="col-2">
            <InputField
              label="Fixed rent (pcm)"
              dataTest=""
              type={InputFieldType.NUMBER}
              unit="(HK$/mo)"
              fieldKey="rent"
              value={rent?.toString() || null}
              disabled={disabled}
              onChange={onChangeDecimal}
              isDecimal={true}
              delimiter="COMMA"
            />
          </div>
        )}

        {type === RentReviewType.Increment && (
          <div className="col-2">
            <InputField
              label="Percentage increment"
              dataTest=""
              type={InputFieldType.NUMBER}
              unit="(%)"
              fieldKey="increment"
              value={increment?.toString() || null}
              disabled={disabled}
              onChange={onChangeDecimal}
              isDecimal={true}
              delimiter="COMMA"
            />
          </div>
        )}
      </div>
    </div>
  );
};
export default OptionToRenewForm;

import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'hooks';
import ContractHistoryNavigator from 'components/ContractHistoryNavigator';
import TabContainer from 'components/TabContainer';
import {
  clearContractCreation,
  initContractAndContractEventCreation,
  setActiveContractEvent,
} from 'store/contractsV2/contractDetailSliceV2';
import { getContract2 } from 'common/api/contracts2';
import { MODE_OPTIONS, ViewProps } from 'common/api/miscellaneous';
import ContractForm from '../ContractForm';
import ContractToolbar from './ContractToolbar';

interface ContractPage extends ViewProps {
  contractId?: string;
}

const Contract = ({ isViewPage, contractId }: ContractPage): JSX.Element => {
  const dispatch = useAppDispatch();
  const { contractEvents } = useAppSelector(({ contractDetailV2 }) => contractDetailV2);

  const [mode, setMode] = useState<MODE_OPTIONS>(isViewPage ? MODE_OPTIONS.READ : MODE_OPTIONS.EDIT);
  const { id } = useParams();
  const contractID = contractId ? contractId : id;

  useEffect(() => {
    // Is this an existing contract?
    if (isViewPage && contractID) {
      dispatch(getContract2({ id: contractID }));
      // Starting a new contract
    } else {
      dispatch(initContractAndContractEventCreation());
    }

    // leaving contracts page, flush out active contract state
    return () => {
      dispatch(clearContractCreation());
    };
  }, [isViewPage, contractID]);

  const setActiveContractHistoryItem = (id: string) => dispatch(setActiveContractEvent(id));

  const renderToolbar = () => {
    if (mode === MODE_OPTIONS.EDIT) {
      return (
        <ContractToolbar
          mode={mode}
          setMode={setMode}
          isViewPage={isViewPage}
        />
      );
    }
    return <></>;
  };

  return (
    <TabContainer
      mode={mode}
      tabToolbar={renderToolbar()}
    >
      {mode === MODE_OPTIONS.READ ? (
        <ContractHistoryNavigator
          setActiveItem={setActiveContractHistoryItem}
          onUpdate={() => setMode(MODE_OPTIONS.EDIT)}
          events={contractEvents}
        />
      ) : (
        <></>
      )}
      <ContractForm
        mode={mode}
        isViewPage={isViewPage}
      />
    </TabContainer>
  );
};

export default Contract;

import { RootState } from 'store';
import { useAppSelector, useChangeContext } from 'hooks';
import { PageHeaderProps } from 'layouts/PageHeader';
import PageLayout from 'layouts/PageLayout';
import DashboardScopeSelector from './DashboardScopeSelector';
import DashboardTabs from './DashboardTabConfig';
import './DashboardPage.scss';

const DashboardPage = () => {
  const { premisesLoading } = useAppSelector((state: RootState) => state.contractDetail);
  const { isLoading: stackingPlanLoading } = useAppSelector((state: RootState) => state.stackingPlan);
  const { isLoading: forecastLoading } = useAppSelector((state: RootState) => state.forecasts);
  const { isLoading: portfoliosLoading } = useAppSelector((state: RootState) => state.portfoliosListing);
  const { summaryStatsLoading } = useAppSelector((state: RootState) => state.dashboardTab);
  const { isLoading: forecastUploading } = useAppSelector((state: RootState) => state.propertyDetail);
  const { isLoading: forecasts2Loading } = useAppSelector((state: RootState) => state.forecasts2);

  useChangeContext();

  const loading =
    forecastLoading ||
    forecastUploading ||
    stackingPlanLoading ||
    premisesLoading ||
    portfoliosLoading ||
    summaryStatsLoading;

  const getPageHeaderProps = (): PageHeaderProps => {
    return {
      customTitle: <DashboardScopeSelector />,
    };
  };

  return (
    <PageLayout
      pageSpinner={loading}
      pageHeader={getPageHeaderProps()}
      id="analytics-dashboard"
      className="analytics-dashboard"
    >
      <DashboardTabs />
    </PageLayout>
  );
};

export default DashboardPage;

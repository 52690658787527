import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'apollo';
import { RootState } from 'store';
import { IChatBotMessage } from 'store/chatBot/chatBotSlice';
import { getCurrentLandlordEntityId } from 'utils/tsHelper';

export interface IContextState {
  transactionId: string | null;
  transactionIdentifier: string | null;
  tenancyId: string | null;
  tenancyIdentifier: string | null;
  tenantId: string | null;
  propertyId: string | null;
  landlordId: string | null;
  startDate: string | null;
  buildingName: string | null;
}

interface EvaluateAIQuestionQueryVariables {
  question: string;
  params: any;
}

// export enum AIQuestions {
//   Question1 = 'Does this deal impact any upcoming rent reviews?',
//   Question2 = 'When is the earliest date that JP Morgan can surrender the premises?',
//   Question3 = `What is JP Morgan's ROFO option?`,
//   Question4 = `What is JP Morgan's upcoming rent review?`,
//   Question5 = `What percentage higher or lower is JP Morgan's rent compared to a similar premise size in Asia Commerce Centre?`,
// }

export const EVALAUTE_AI_QUESTION = (question: string, state: IContextState) => {
  console.log('question: ', question);
  console.log('state: ', state);
  if (!question) throw new Error('Evalaute AI Question:  The question is missing');
  const params = JSON.stringify(state);

  const queryVariables: EvaluateAIQuestionQueryVariables = {
    question,
    params,
  };

  return {
    query: gql`
      query ($question: String!, $params: Json) {
        evaluateAiQuestion(question: $question, params: $params) {
          result
        }
      }
    `,
    variables: queryVariables,
  };
};

export const evaluateAIQuestion = createAsyncThunk(
  'chatBotState/evaluateAIQuestion',
  async ({ message, state }: { message: IChatBotMessage; state: IContextState }) => {
    const response = await client.query(EVALAUTE_AI_QUESTION(message.message, state));
    return response;
  },
);

// evaluateUserPrompt
const evaluateUserPrompt = async (userPrompt: string) => {
  let state = { transactionId: '1234', userId: '567', landlordId: '890' };
  const res = await fetch('http://localhost:12000/chat-bot/ask', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ userPrompt, state }),
  });
  return res.json();
};

export const localAPIFallback = createAsyncThunk(
  'chatBotState/localAPIFallback',
  async ({ message, state }: { message: IChatBotMessage; state: IContextState }) => {
    const response = await evaluateUserPrompt(message.message);
    return response;
  },
);

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import { BreadCrumbItem } from 'layouts/BreadCrumb';
import { PageHeaderProps } from 'layouts/PageHeader';
import { CreateNewDropdownProps, PageHeaderToolbarProps } from 'layouts/PageHeader/PageHeaderToolbar';
import PageLayout from 'layouts/PageLayout';
import DimmerLoader from 'atoms/DimmerLoader';
import { DropdownItem } from 'atoms/DropdownMenu';
import 'views/provisions/Dashboard/ProvisionsDashboard.scss';
import {
  createNewParameter,
  createNewTableParameter,
  updateParamViewSelectedGroup,
} from 'store/parameters/parameterDetailSlice';
import { resetSelectedGroup } from 'store/parameters/parametersListingSlice';
import {
  MODAL_MODE_OPTIONS,
  PARAMETERS_CLASSIFICATION_OPTIONS,
  PARAMETERS_DASHBOARD_VIEW_OPTIONS,
} from 'common/api/miscellaneous';
import { listParameterCategoriesAndGroups, listParameterTablesPage, listParameters } from 'common/api/parameters';
import ParameterTablesListing from './ParameterTablesListing';
import ParametersCardsView from './ParametersCardsView';
import ParametersHeaderToolbar from './ParametersHeaderToolbar';
import ParametersListing from './ParametersListing';
import './paramSearch.scss';

interface TableProps {
  paramView: PARAMETERS_DASHBOARD_VIEW_OPTIONS;
  setParamId: () => void;
}

const Table = ({ paramView, setParamId }: TableProps): React.ReactElement => {
  switch (paramView) {
    case PARAMETERS_DASHBOARD_VIEW_OPTIONS.GROUP:
      return <ParametersCardsView />;
    case PARAMETERS_DASHBOARD_VIEW_OPTIONS.PARAMETER:
      return <ParametersListing setParamId={setParamId} />;
    case PARAMETERS_DASHBOARD_VIEW_OPTIONS.TABLE:
      return <ParameterTablesListing setParamId={setParamId} />;
    default:
      return <>Unknown ParamType: {paramView}</>;
  }
};

export interface SetModalStatesProps {
  type: PARAMETERS_CLASSIFICATION_OPTIONS;
  mode: MODAL_MODE_OPTIONS;
}

const ParamSearch = ({
  setValue,
}: {
  searchParams: boolean;
  searchGroups: boolean;
  setValue: () => void;
}): JSX.Element => {
  const dispatch = useAppDispatch();

  const { isLoading, paramView, paramViewIsNotGroup } = useAppSelector((state: RootState) => state.parametersListing);
  // const { paramViewSelectedGroup } = useAppSelector((state: RootState) => state.parameterDetail);

  // const [mode, setMode] = useState<MODAL_MODE_OPTIONS>(MODAL_MODE_OPTIONS.CREATE);
  // const [modalType, setModalType] = useState<PARAMETERS_CLASSIFICATION_OPTIONS>(
  //   PARAMETERS_CLASSIFICATION_OPTIONS.CATEGORY,
  // );

  useEffect(() => {
    if (paramView === PARAMETERS_DASHBOARD_VIEW_OPTIONS.GROUP) {
      dispatch(listParameterCategoriesAndGroups());
    }
  }, [dispatch, paramView, paramViewIsNotGroup]);

  const urlSearch: string = window.location.search;

  useEffect(() => {
    if (urlSearch === '') {
      dispatch(updateParamViewSelectedGroup({}));
    } else {
      if (!paramViewIsNotGroup) {
        const url = new URL(window.location.href);
        url.searchParams.delete('group');
        window.history.pushState({}, '', url);
      }
    }
  }, [urlSearch, paramViewIsNotGroup, dispatch]);

  return (
    <div className="parameters-search-modal">
      <DimmerLoader status={isLoading} />
      <ParametersHeaderToolbar />
      <Table
        paramView={paramView}
        setParamId={setValue}
      />
    </div>
  );
};

export default ParamSearch;

import Decimal from 'decimal.js';
import ManagementCharge from 'common/model/ManagementCharge';
import ManagementChargeInput from 'common/model/ManagementChargeInput';
import ManagementChargeMonthlyAcFeesPayable from 'common/model/ManagementChargeMonthlyAcFeesPayable';
import OutOfHoursAcChargesPayable from 'common/model/OutOfHoursAcChargesPayable';
import RateType from 'common/model/RateType';
import { convertDateToISO } from 'utils/utils-date';

export interface ManagementChargeInputClone
  extends Omit<
    ManagementChargeInput,
    | 'finalCharge'
    | 'standardRate'
    | 'standardRateDiscount'
    | 'type'
    | 'startDate'
    | 'endDate'
    | 'payableByTenant'
    | 'monthlyAcFeesPayable'
    | 'outOfHoursAcPayble'
  > {
  finalCharge: Decimal | null;
  payableByTenant: boolean | null;
  standardRate: Decimal | null;
  standardRateDiscount: Decimal | null;
  type: RateType | null;
  startDate: Date | null;
  endDate: Date | null;
  monthlyAcFeesPayable: ManagementChargeMonthlyAcFeesPayable | null;
  outOfHoursAcPayble: OutOfHoursAcChargesPayable | null;
}

export class CManagementCharge implements ManagementChargeInputClone {
  finalCharge: Decimal | null;
  standardRate: Decimal | null;
  standardRateDiscount: Decimal | null;
  type: RateType | null;
  startDate: Date | null;
  endDate: Date | null;
  payableByTenant: boolean | null;
  monthlyAcFeesPayable: ManagementChargeMonthlyAcFeesPayable | null;
  outOfHoursAcPayble: OutOfHoursAcChargesPayable | null;

  constructor(input: ManagementCharge | null) {
    this.finalCharge = input && input.finalCharge ? new Decimal(input.finalCharge) : null;
    this.payableByTenant = input ? input.payableByTenant : null;
    this.monthlyAcFeesPayable = input ? input.monthlyAcFeesPayable : null;
    this.outOfHoursAcPayble = input ? input.outOfHoursAcPayble : null;
    this.standardRate = input && input.standardRate ? new Decimal(input.standardRate) : null;
    this.standardRateDiscount = input && input.standardRateDiscount ? new Decimal(input.standardRateDiscount) : null;
    this.type = input ? input.type : null;
    this.startDate = input && input.startDate ? new Date(input.startDate) : null;
    this.endDate = input && input.endDate ? new Date(input.endDate) : null;
  }

  static fragment() {
    return `
      finalCharge
      standardRate 
      standardRateDiscount
      type
      startDate
      endDate
      monthlyAcFeesPayable
      outOfHoursAcPayble
      payableByTenant
    `;
  }

  static formatForAPI(charge: CManagementCharge) {
    return {
      finalCharge: charge.finalCharge ? parseFloat(charge.finalCharge.toString()) : null,
      monthlyAcFeesPayable: charge.monthlyAcFeesPayable,
      outOfHoursAcPayble: charge.outOfHoursAcPayble,
      payableByTenant: charge.payableByTenant,
      standardRate: charge.standardRate ? parseFloat(charge.standardRate.toString()) : null,
      standardRateDiscount: charge.standardRateDiscount ? parseFloat(charge.standardRateDiscount.toString()) : null,
      type: charge.type,
      endDate: convertDateToISO(charge.endDate),
      startDate: convertDateToISO(charge.startDate),
    };
  }
}

import { useEffect, useState } from 'react';
import { Form, Grid } from 'semantic-ui-react';
import { Input } from 'semantic-ui-react';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import SectionComponent from 'layouts/SectionComponent';
import InputField, { InputFieldType } from 'atoms/FormField/Input';
import ModalTemplate from 'components/ModalTemplate';
// import EditorField from 'atoms/FormField/EditorField';
import { updateActiveTranslateToContract } from 'store/translatorsToContract/translatorDetailSlice';
import { Parameter, ParameterGroup } from 'common/model/TranslateToContract';
import {
  MODAL_MODE_OPTIONS,
  MODE_OPTIONS,
  ModeProps,
  PARAMETERS_CLASSIFICATION_OPTIONS,
} from 'common/api/miscellaneous';
import ParamSearch from './ParamSearch';
import ParameterGroupSection from './ParameterGroupSection';
import ParameterSection from './ParameterSection';
import TranslateToContractDefinition from './TranslateToContractDefinition';

const TranslatorToContractContent = ({ mode }: ModeProps): JSX.Element => {
  const { activeTranslateToContract } = useAppSelector((state: RootState) => state.translatorDetail);
  const parameters = activeTranslateToContract?.parameters ?? [];
  // @ts-ignore
  const parameterGroup = activeTranslateToContract?.parameterGroup ?? [];

  return (
    <Form>
      <Grid>
        <Grid.Row className="pm-none">
          <Grid.Column
            width={8}
            className="p-l-none"
          >
            <SectionComponent title="1. Translate to contract definition">
              <TranslateToContractDefinition mode={mode} />
            </SectionComponent>
          </Grid.Column>

          <Grid.Column width={8}>
            {parameters.length ? (
              <ParameterSection
                //  @ts-ignore
                parameters={parameters}
                mode={mode}
              />
            ) : (
              <></>
            )}
            {parameterGroup.length ? (
              <ParameterGroupSection
                parameterGroup={parameterGroup}
                mode={mode}
              />
            ) : (
              <></>
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Form>
  );
};

export default TranslatorToContractContent;

// const editorSection = () => {
//   return (
//           <SectionComponent
//             title={<>2. Parameters <span className="required-indicator-title">*</span></>}
//           >
//              <EditorField
//               onChange={onChange}
//               value={activeTranslateToContract.function}
//               placeholder="Type here..."
//               disabled={mode === MODE_OPTIONS.READ}
//               fieldKey="function"
//               height="500"
//               codeSample={true}
//               code={true}
//             />
//           </SectionComponent>
//   );
// }

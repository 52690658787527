import { ModeProps, ViewProps } from 'common/api/miscellaneous';
import AcFees from './AcFees';
import GovernmentRates from './GovernmentRates';
import GovernmentRent from './GovernmentRent';
import ManagementCharges from './ManagementCharges';

const OtherCharges = ({ mode, isViewPage }: ModeProps & ViewProps) => {
  return (
    <div className="d-flex-column flex-gap-sm">
      <ManagementCharges
        mode={mode}
        isViewPage={isViewPage}
      />
      <AcFees
        mode={mode}
        isViewPage={isViewPage}
      />
      <GovernmentRent
        mode={mode}
        isViewPage={isViewPage}
      />
      <GovernmentRates
        mode={mode}
        isViewPage={isViewPage}
      />
    </div>
  );
};

export default OtherCharges;

import { AnyAction, Dispatch } from '@reduxjs/toolkit';
import { SetStateAction, useEffect, useState } from 'react';
import { Button, Form, Grid } from 'semantic-ui-react';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import TextAreaField from 'atoms/FormField/TextArea';
import CardParameters from 'components/CardParameters';
import ModalTemplate from 'components/ModalTemplate';
import { ChangesBoxType } from 'views/transactions/CreatorViewer/Tabs/ApprovalTab/ApprovalChanges';
import { ParameterModes } from 'store/miscellaneous/miscellaneousSlice';
import {
  resetTransactionParameterGroupAnswers,
  setActiveMessage,
  updateActiveGroupIds,
} from 'store/transactions/transactionDetailSlice';
import ExecuteContext from 'common/model/ExecuteContext';
import ValidApprovalStates from 'common/model/ValidApprovalStates';
import { listContextParameters, listParameterGroups } from 'common/api/parameters';
import { AnswerProps, TransactionMessageClone } from 'common/api/policies';
import { listTransactionMessages, updateTransactionMessage } from 'common/api/transactions';
import { createTransactionApprovalAnswers } from 'common/api/transactions/approvals/createTransactionApprovalAnswers';
import { createTransactionDiscussionApproval } from 'common/api/transactions/approvals/createTransactionDiscussionApproval';
import { deleteOldTransactionApprovalAnswers } from 'common/api/transactions/approvals/deleteOldTransactionApprovalAnswers';
import { listTransactionDiscussionApprovals } from 'common/api/transactions/approvals/listTransactionDiscussionApprovals';
import { updateTransactionDiscussionApproval } from 'common/api/transactions/approvals/updateTransactionDiscussionApproval';
import { translateToContract } from 'common/api/translatorsToContract';
import { checkNotEmpty } from 'utils/tsValidator';
import TemporaryDeviation from './TemporaryDeviation';
import './LinkToParametersModal.scss';

const getContextParameters = (
  transactionId: string | undefined,
  mode: ParameterModes,
  conditional: boolean,
  dispatch: Dispatch<AnyAction>,
) => {
  dispatch(
    listContextParameters({
      context: ExecuteContext.Transaction,
      contextId: transactionId,
      provisionId: null,
      conditional,
      mode,
    }),
  );
};

export const getAnswersToBeDeleted = (temporaryAnswers: AnswerProps[]) => {
  const savedAnswers = temporaryAnswers.filter((answer: AnswerProps) => answer.blockRef === undefined);

  const unSavedAnswers = temporaryAnswers.filter((answer: AnswerProps) => answer.blockRef !== undefined);

  let answerIds = [] as string[];
  for (let i = 0; i < unSavedAnswers.length; i++) {
    const answer = savedAnswers.find(
      (answer: AnswerProps) =>
        answer.paramRef.parameterId === unSavedAnswers[i].paramRef.parameterId &&
        answer.paramRef.tableId === unSavedAnswers[i].paramRef.tableId &&
        answer.paramRef.index === unSavedAnswers[i].paramRef.index,
    );
    if (answer) {
      answerIds.push(answer.id as string);
    }
  }
  return answerIds;
};

export const createApprovalAnswers = (
  approvalType: ChangesBoxType,
  approvalId: string | undefined,
  temporaryAnswers: AnswerProps[],
  answerIds: string[],
  transactionId: string | undefined,
  runFormatterAndConditions: (() => void) | undefined,
  mode: ParameterModes,
  conditional: boolean,
  dispatch: Dispatch<AnyAction>,
) => {
  dispatch(deleteOldTransactionApprovalAnswers({ answerIds })).then((response: any) => {
    if (response.meta.requestStatus === 'fulfilled') {
      const newAnswers: AnswerProps[] = temporaryAnswers.filter((answer: AnswerProps) => answer.blockRef !== undefined);
      if (newAnswers.length !== 0) {
        dispatch(createTransactionApprovalAnswers({ approvalType, approvalId })).then((response: any) => {
          if (response.meta.requestStatus === 'fulfilled') {
            if (runFormatterAndConditions) {
              runFormatterAndConditions();
            }

            // might need to be updated and added inside submit of approvals well
            dispatch(translateToContract({ finalised: false, toast: true })).then((response: any) => {
              if (response.meta.requestStatus === 'fulfilled') {
                getContextParameters(transactionId, mode, conditional, dispatch);
              }
            });
          }
        });
      }
    }
  });
};

const Content = ({
  answerIds,
  approverMessage,
  setApproverMessage,
  draftMessage,
  setDraftMessage,
}: {
  answerIds: string[];
  approverMessage: string | undefined;
  setApproverMessage: Dispatch<SetStateAction<any>>;
  draftMessage: string | undefined;
  setDraftMessage: Dispatch<SetStateAction<any>>;
}): JSX.Element => {
  const { showParametersForm } = useAppSelector((state: RootState) => state.transactionDetail);

  /* MIGHT BE NEEDED
  const onChange = (_key: string, value: string): void => {
    dispatch(updateActiveGroupIds({ value }));
  };*/

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column width={8}>
          <Form>
            <TextAreaField
              label="Message to approver"
              required={true}
              value={approverMessage as string}
              onChange={(key: string, value: string) => setApproverMessage(value)}
              fieldKey="approverMessage"
            />
          </Form>
        </Grid.Column>
        <Grid.Column width={8}>
          <Form>
            <TextAreaField
              label="Draft message to tenant"
              required={true}
              value={draftMessage as string}
              onChange={(key: string, value: string) => setDraftMessage(value)}
              fieldKey="draftMessage"
            />
          </Form>
        </Grid.Column>
      </Grid.Row>
      {/*
      <Grid.Row>
        <Grid.Column width={8}>
          <SelectField
            label="Group"
            value={activeGroupIds}
            placeholder="Select group..."
            options={parameterGroupsOptions}
            onChange={onChange}
            multiple={true}
            fieldKey="parameterGroupId"
            search={true}
          />
        </Grid.Column>
      </Grid.Row>
      */}
      <Grid.Row>
        <Grid.Column width={16}>
          <TemporaryDeviation answerIds={answerIds} />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={16}>
          <CardParameters showParametersForm={showParametersForm} />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

const LinkToParametersModal = ({
  isOpen,
  setIsOpen,
}: {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  message?: string;
}): JSX.Element => {
  const { activeTransaction, activeTransactionAnswers, activeMessageId, transactionMessages } = useAppSelector(
    (state: RootState) => state.transactionDetail,
  );

  const { parameterMode, conditional } = useAppSelector((state: RootState) => state.miscellaneous);

  const discussionApproval = activeTransactionAnswers.filter(
    (answer: AnswerProps) => answer?.transactionMessage?.id === activeMessageId,
  )[0]?.transactionDiscussionApproval;

  const discussionApprovalId = discussionApproval?.id;
  const draftApprovalMessage = discussionApproval?.draftMessage;

  const temporaryAnswers = activeTransactionAnswers.filter(
    (answer: AnswerProps) =>
      (answer?.transactionMessage?.id === activeMessageId || answer?.transactionMessageId === activeMessageId) &&
      answer?.approvalState === ValidApprovalStates.Pending,
  );

  const temporaryAnswersExists: boolean = temporaryAnswers.length !== 0;
  const answerIds = getAnswersToBeDeleted(temporaryAnswers);

  const dispatch = useAppDispatch();

  const message: TransactionMessageClone | undefined = transactionMessages.find(
    (transactionMessage: TransactionMessageClone) => transactionMessage.id === activeMessageId,
  );

  const activeMessage = message?.message;

  const [approverMessage, setApproverMessage] = useState(activeMessage);
  const [draftMessage, setDraftMessage] = useState(draftApprovalMessage);

  useEffect(() => {
    if (activeMessage) {
      setApproverMessage(activeMessage);
    }
    if (draftApprovalMessage) {
      setDraftMessage(draftApprovalMessage);
    }
  }, [activeMessage, draftApprovalMessage]);

  useEffect(() => {
    dispatch(listTransactionDiscussionApprovals());
  }, [dispatch]);

  const toggleModal = (value: boolean): void => {
    setIsOpen(value);
  };

  useEffect(() => {
    dispatch(listParameterGroups());
  }, [dispatch]);

  const onModalClose = () => {
    dispatch(updateActiveGroupIds({ value: [] }));
    dispatch(resetTransactionParameterGroupAnswers());
    dispatch(setActiveMessage({ messageId: null }));
    getContextParameters(activeTransaction.id, parameterMode, conditional, dispatch);
    setDraftMessage('');
  };

  const onUpdateAnswers = (): void => {
    if (temporaryAnswers.length !== 0 && (discussionApprovalId === null || discussionApprovalId === undefined)) {
      dispatch(createTransactionDiscussionApproval({ draftMessage })).then((response: any) => {
        if (response.meta.requestStatus === 'fulfilled') {
          const discussionApprovalId = response.payload.data.createTransactionDiscussionApproval.id;
          createApprovalAnswers(
            ChangesBoxType.Discussion,
            discussionApprovalId,
            temporaryAnswers,
            answerIds,
            activeTransaction.id,
            undefined,
            parameterMode,
            conditional,
            dispatch,
          );
        }
      });
    } else {
      createApprovalAnswers(
        ChangesBoxType.Discussion,
        discussionApprovalId,
        temporaryAnswers,
        answerIds,
        activeTransaction.id,
        undefined,
        parameterMode,
        conditional,
        dispatch,
      );
      if (discussionApprovalId) {
        dispatch(updateTransactionDiscussionApproval({ approvalId: discussionApprovalId as string, draftMessage }));
      }
    }
  };

  const onSave = () => {
    if (message) {
      const messageClone = { ...message, message: approverMessage as string };
      dispatch(updateTransactionMessage(messageClone)).then((response: any) => {
        if (response.meta.requestStatus === 'fulfilled') {
          dispatch(listTransactionMessages({ thread: message.thread }));
        }
      });
    }
    setIsOpen(false);
    onUpdateAnswers();
    setApproverMessage('');
    setDraftMessage('');
    dispatch(setActiveMessage({ messageId: null }));
  };

  const checkSaveDisabled = () => {
    return !checkNotEmpty(approverMessage) || !checkNotEmpty(draftMessage);
  };

  return (
    <ModalTemplate
      className={`link-to-parameters-modal ${temporaryAnswersExists ? 'with-temporary-table' : ''}`}
      isOpen={isOpen}
      onToggleModalStatus={toggleModal}
      title="Change parameters related to this discussion"
      onModalClose={onModalClose}
      header={
        <Button
          className="exit-btn save-btn"
          disabled={checkSaveDisabled()}
          onClick={onSave}
        >
          SAVE
        </Button>
      }
    >
      <Content
        answerIds={answerIds}
        approverMessage={approverMessage}
        setApproverMessage={setApproverMessage as Dispatch<SetStateAction<any>>}
        draftMessage={draftMessage}
        setDraftMessage={setDraftMessage as Dispatch<SetStateAction<any>>}
      />
    </ModalTemplate>
  );
};

export default LinkToParametersModal;

import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'apollo';
import { getCurrentLandlordEntityId } from 'utils/tsHelper';

export enum UsagePoint {
  ManagementCharge = 'ManagementCharge',
  ACFee = 'ACFee',
}

export const EVALUATE_MANAGEMENT_CHARGE_OR_AC_FEE_FINAL_CHARGE_OUTPUT = (
  standardRate: number,
  standardRateDiscount: number,
) => {
  const currentLandlordEntityId = getCurrentLandlordEntityId();

  if (!currentLandlordEntityId) {
    throw new Error(
      'Evaluate Management Charges Or Ac Fees Final Charge Input: Landlord Entity could not be determined. Query aborted.',
    );
  }

  const queryVariables = {
    currentLandlordEntityId,
    standardRate,
    standardRateDiscount,
  };

  return {
    query: gql`
      query ($currentLandlordEntityId: ID!, $standardRate: Decimal!, $standardRateDiscount: Decimal!) {
        evaluateManagementChargeOrAcFeeFinalCharge(
          currentLandlordEntityId: $currentLandlordEntityId
          standardRate: $standardRate
          standardRateDiscount: $standardRateDiscount
        )
      }
    `,
    variables: queryVariables,
  };
};

export const evaluateManagementChargeOrAcFeeFinalCharge = createAsyncThunk(
  'contractDetailV2/evaluateManagementChargeOrAcFeeFinalCharge',
  async ({
    standardRate,
    standardRateDiscount,
    usage,
    index,
  }: {
    standardRate: number;
    standardRateDiscount: number;
    index: number;
    usage: UsagePoint;
  }) => {
    const response = await client.query(
      EVALUATE_MANAGEMENT_CHARGE_OR_AC_FEE_FINAL_CHARGE_OUTPUT(standardRate, standardRateDiscount),
    );
    return response;
  },
);

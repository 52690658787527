import { CAcFee } from 'common/_classes/contractsV2';
import Decimal from 'decimal.js';
import { useEffect } from 'react';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import DateField from 'atoms/FormField/DateInput';
import InputField, { InputFieldType } from 'atoms/FormField/Input';
import SelectField from 'atoms/FormField/Select';
import { updateActiveContractInput } from 'store/contractsV2/contractDetailSliceV2';
import ManagementChargeMonthlyAcFeesPayable from 'common/model/ManagementChargeMonthlyAcFeesPayable';
import OutOfHoursAcChargesPayable from 'common/model/OutOfHoursAcChargesPayable';
import RateType from 'common/model/RateType';
import { UsagePoint, evaluateManagementChargeOrAcFeeFinalCharge } from 'common/api/contracts2';
import { MODE_OPTIONS, ModeProps, ViewProps } from 'common/api/miscellaneous';
import {
  MANAGEMENT_CHARGE_MONTHLY_AC_FEES_PAYABLE_OPTIONS,
  OUT_OF_HOURS_AC_CHARGES_PAYABLE_OPTIONS,
  RATE_TYPE_OPTIONS,
  YES_OR_NO_BOOLEAN_OPTIONS,
} from 'utils/UI';
import { getMaxDateBoundaryForTenancies, getMinDateBoundaryForTenancies } from 'utils/utils-date';

interface ManagementChargesFormProps extends ModeProps, ViewProps {
  index: number;
}

const ManagementChargesForm = ({ mode, index, isViewPage }: ManagementChargesFormProps) => {
  const dispatch = useAppDispatch();

  const { activeContract, activeContractEvent } = useAppSelector(({ contractDetailV2 }: RootState) => contractDetailV2);

  const charges = activeContract?.managementCharges || [];
  const acFees = activeContract?.acFees || [];

  const managementCharge = charges[index];

  const {
    endDate,
    startDate,
    standardRateDiscount,
    standardRate,
    finalCharge,
    type,
    payableByTenant,
    monthlyAcFeesPayable,
    outOfHoursAcPayble,
  } = managementCharge;

  const isStandardType = type === RateType.Standard;
  const isDiscountType = type === RateType.Discount;
  const isFixedType = type === RateType.Fixed;

  const rootKey = 'managementCharges';
  const acFeesRootKey = 'acFees';

  const createACFee = () => {
    const newACfee = new CAcFee(null);
    newACfee.startDate = managementCharge.startDate;
    newACfee.endDate = managementCharge.endDate;
    dispatch(
      updateActiveContractInput({
        key: acFeesRootKey,
        value: [newACfee],
      }),
    );
  };

  const onChangeOutOfHoursAcPayble = (key: string, value: any) => {
    dispatch(
      updateActiveContractInput({
        key: [rootKey, `${index}`, key],
        value: value ? value : null,
      }),
    );

    if (acFees.length === 0) {
      createACFee();
    }

    if (value === OutOfHoursAcChargesPayable.NoAcIs247) {
      dispatch(
        updateActiveContractInput({
          key: [acFeesRootKey, `${index}`],
          value: {
            ...acFees[0],
            outOfHoursFee: null,
            standardHours: new Decimal('24'),
          },
        }),
      );
    } else if (value === OutOfHoursAcChargesPayable.NoAcNotProvidedOutsideStandardHours) {
      dispatch(
        updateActiveContractInput({
          key: [acFeesRootKey, `${index}`],
          value: {
            ...acFees[0],
            outOfHoursFee: null,
          },
        }),
      );
    }
  };

  const onChangeMonthlyAcFeesPayable = (key: string, value: any) => {
    const deleteACFees = () =>
      dispatch(
        updateActiveContractInput({
          key: acFeesRootKey,
          value: [],
        }),
      );

    const onChange = () => {
      dispatch(
        updateActiveContractInput({
          key: [rootKey, `${index}`],
          value: {
            ...managementCharge,
            monthlyAcFeesPayable: value,
            outOfHoursAcPayble: null,
          },
        }),
      );

      deleteACFees();
    };

    switch (value) {
      case '':
        onChange();
        break;

      case ManagementChargeMonthlyAcFeesPayable.NotApplicableAcNotProvided:
        onChange();
        break;

      case ManagementChargeMonthlyAcFeesPayable.NotIncludedAdditionalAcFeesPaidByTenant:
        dispatch(
          updateActiveContractInput({
            key: [rootKey, `${index}`, key],
            value: value ? value : null,
          }),
        );

        acFees.length === 0 ? createACFee() : null;
        break;

      case ManagementChargeMonthlyAcFeesPayable.IncludedInManagementCharges:
        dispatch(
          updateActiveContractInput({
            key: [rootKey, `${index}`, key],
            value: value ? value : null,
          }),
        );

        deleteACFees();
        break;
    }
  };

  const onChangeDate = (key: string, value: any) => {
    dispatch(
      updateActiveContractInput({
        key: [rootKey, `${index}`, key],
        value: value ? value : null,
      }),
    );

    if (acFees.length > 0) {
      dispatch(
        updateActiveContractInput({
          key: [acFeesRootKey, '0', key],
          value: value ? value : null,
        }),
      );
    }
  };

  const onChangePayableByTenant = (key: string, value: any) => {
    if (value) {
      dispatch(
        updateActiveContractInput({
          key: [rootKey, `${index}`, key],
          value,
        }),
      );
    } else {
      dispatch(
        updateActiveContractInput({
          key: [rootKey, `${index}`],
          value: {
            ...managementCharge,
            payableByTenant: value,
            standardRateDiscount: null,
            finalCharge: null,
            type: RateType.Standard,
          },
        }),
      );
    }
  };

  const onChangeDecimal = (key: string, value: string) => {
    dispatch(
      updateActiveContractInput({
        key: [rootKey, `${index}`, key],
        value: value ? new Decimal(value) : null,
      }),
    );

    if ((key === 'standardRateDiscount' || key === 'standardRate') && !value) {
      dispatch(
        updateActiveContractInput({
          key: [rootKey, `${index}`, 'finalCharge'],
          value: null,
        }),
      );
    }
  };

  const onChangeType = (_key: string, value: string) => {
    switch (value) {
      case RateType.Standard:
        dispatch(
          updateActiveContractInput({
            key: [rootKey, `${index}`],
            value: {
              ...managementCharge,
              standardRateDiscount: new Decimal('0'),
              finalCharge: managementCharge.standardRate,
              type: value,
            },
          }),
        );
        break;

      case RateType.Fixed:
        dispatch(
          updateActiveContractInput({
            key: [rootKey, `${index}`],
            value: {
              ...managementCharge,
              standardRateDiscount: new Decimal('0'),
              type: value,
            },
          }),
        );
        break;

      case RateType.Discount:
        dispatch(
          updateActiveContractInput({
            key: [rootKey, `${index}`],
            value: {
              ...managementCharge,
              standardRateDiscount: null,
              type: value,
              finalCharge: null,
            },
          }),
        );
        break;
    }
  };

  const disabled = mode === MODE_OPTIONS.READ || isViewPage;
  const required = mode === MODE_OPTIONS.EDIT;

  const chargeNotPayableByTenant = payableByTenant === false;

  const outOfHoursAcPaybleApplicable =
    monthlyAcFeesPayable && monthlyAcFeesPayable !== ManagementChargeMonthlyAcFeesPayable.NotApplicableAcNotProvided;

  useEffect(() => {
    if (type === RateType.Discount && standardRate && standardRateDiscount) {
      dispatch(
        evaluateManagementChargeOrAcFeeFinalCharge({
          standardRate: parseFloat(standardRate.toString()),
          standardRateDiscount: parseFloat(standardRateDiscount.toString()),
          usage: UsagePoint.ManagementCharge,
          index,
        }),
      );
    }
  }, [type, standardRate, standardRateDiscount]);

  return (
    <div>
      <div className="grid m-t-s pm-none">
        <div className="col-2">
          <DateField
            minDate={getMinDateBoundaryForTenancies(
              activeContractEvent?.startDate,
              activeContractEvent?.endDate,
              charges,
              index,
              'startDate',
            )}
            maxDate={getMaxDateBoundaryForTenancies(
              activeContractEvent?.startDate,
              activeContractEvent?.endDate,
              charges,
              index,
              'startDate',
            )}
            label="Start date"
            fieldKey="startDate"
            dataTest=""
            value={startDate}
            disabled={disabled}
            required={required}
            onChange={onChangeDate}
          />
        </div>

        <div className="col-2">
          <DateField
            minDate={getMinDateBoundaryForTenancies(
              activeContractEvent?.startDate,
              activeContractEvent?.endDate,
              charges,
              index,
              'endDate',
            )}
            maxDate={getMaxDateBoundaryForTenancies(
              activeContractEvent?.startDate,
              activeContractEvent?.endDate,
              charges,
              index,
              'endDate',
            )}
            label="End date"
            fieldKey="endDate"
            dataTest=""
            value={endDate}
            disabled={disabled}
            required={required}
            onChange={onChangeDate}
          />
        </div>

        <div className="col-2">
          <SelectField
            label="Payable by tenant"
            fieldKey="payableByTenant"
            dataTest=""
            value={payableByTenant}
            disabled={disabled}
            required={required}
            options={YES_OR_NO_BOOLEAN_OPTIONS}
            onChange={onChangePayableByTenant}
          />
        </div>
      </div>

      <div className="grid m-t-s pm-none">
        <div className="col-2">
          <SelectField
            label="Standard/discount/fixed"
            fieldKey="type"
            dataTest=""
            value={type}
            disabled={disabled || chargeNotPayableByTenant}
            required={required && !chargeNotPayableByTenant}
            options={RATE_TYPE_OPTIONS}
            onChange={onChangeType}
          />
        </div>

        <div className="col-2">
          <InputField
            label="Standard rate (psf pcm)"
            dataTest=""
            type={InputFieldType.NUMBER}
            unit="(HK$/sf/mo)"
            fieldKey="standardRate"
            value={standardRate?.toString() || null}
            disabled={disabled}
            required={required}
            onChange={onChangeDecimal}
            isDecimal={true}
            delimiter="COMMA"
          />
        </div>

        {chargeNotPayableByTenant ? (
          <></>
        ) : (
          <>
            <div className="col-2">
              <InputField
                label="Discount from standard rate"
                dataTest=""
                type={InputFieldType.NUMBER}
                unit="(%)"
                fieldKey="standardRateDiscount"
                value={standardRateDiscount?.toString() || null}
                disabled={disabled || !type || isStandardType || isFixedType}
                required={required && type !== null && !isStandardType && !isFixedType}
                onChange={onChangeDecimal}
                isDecimal={true}
                delimiter="COMMA"
              />
            </div>
            <div className="col-2">
              <InputField
                label="Final mgt. charges (psf pcm)"
                dataTest=""
                type={InputFieldType.NUMBER}
                unit="(HK$/sf/mo)"
                fieldKey="finalCharge"
                value={finalCharge?.toString() || null}
                disabled={disabled || !type || isStandardType || isDiscountType}
                required={required && type !== null && !isStandardType && !isDiscountType}
                onChange={onChangeDecimal}
                isDecimal={true}
                delimiter="COMMA"
              />
            </div>
          </>
        )}
      </div>

      <div className="grid m-t-s pm-none">
        <div className="col-2">
          <SelectField
            label="Monthly AC fees payable?"
            fieldKey="monthlyAcFeesPayable"
            dataTest=""
            value={monthlyAcFeesPayable}
            disabled={disabled}
            options={MANAGEMENT_CHARGE_MONTHLY_AC_FEES_PAYABLE_OPTIONS}
            onChange={onChangeMonthlyAcFeesPayable}
            clearable={true}
          />
        </div>

        {outOfHoursAcPaybleApplicable ? (
          <div className="col-2">
            <SelectField
              label="Out-of-hours AC fees payable?"
              fieldKey="outOfHoursAcPayble"
              dataTest=""
              value={outOfHoursAcPayble}
              disabled={disabled}
              options={OUT_OF_HOURS_AC_CHARGES_PAYABLE_OPTIONS}
              onChange={onChangeOutOfHoursAcPayble}
              clearable={true}
            />
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default ManagementChargesForm;
